import { useCallback, useMemo } from 'react';
import { useLocation, useSearch } from 'wouter';

export const JOIN_CHAR = '~';
export const JOIN_IN_CHAR = ';';

export const OPERATORS = {
  eq: 'eq~',
  in: 'in~',
  like: 'like~',
  glt: 'glt~',
  lteq: 'lteq~',
  gteq: 'gteq~',
};

export function useQuery() {
  return new URLSearchParams(useSearch());
}

export function useQueryParam(name: string) {
  const [location, navigate] = useLocation();
  const query = useQuery();
  const value = query.get(name)?.split(JOIN_CHAR)[1];

  const setValue = useCallback(
    (val: string) => {
      const params = new URLSearchParams(query);

      if (val) {
        params.set(name, val);
      } else {
        params.delete(name);
      }

      // navigate does not add the '?' to distinguish the query params from the pathname
      const paramStr = params.toString();
      navigate(paramStr ? `?${paramStr}` : location);
    },
    [location, navigate, name, query],
  );

  const remove = useCallback(() => {
    setValue('');
  }, [setValue]);

  return {
    value,
    isActive: !!value,
    setValue,
    remove,
  };
}

export function useListQueryParam(name: string) {
  const [location, navigate] = useLocation();
  const query = useQuery();

  // In this case, two characters are used to distinguish the information
  // the value is like: in~{value1};{value2};...
  // so to have only the list of values, we first split by '~' to separate
  // the operator from the values and get the values with the [1]
  const rawValue = query.get(name)?.split(JOIN_CHAR)[1];

  const values = useMemo(
    () => (rawValue ? rawValue.split(JOIN_IN_CHAR) : []),
    [rawValue],
  );

  const setValues = useCallback(
    (vals: string[]) => {
      const params = new URLSearchParams(query);
      if (vals.length > 0) {
        params.set(name, `${OPERATORS.in}${vals.join(JOIN_IN_CHAR)}`);
      } else {
        params.delete(name);
      }

      // navigate does not add the '?' to distinguish the query params from the pathname
      const paramsStr = params.toString();
      navigate(paramsStr ? `?${paramsStr}` : location);
    },
    [navigate, location, name, query],
  );

  const toggleValue = useCallback(
    (val: string) => {
      const list = new Set(values);
      if (list.has(val)) {
        list.delete(val);
      } else {
        list.add(val);
      }

      setValues([...list]);
    },
    [setValues, values],
  );

  const remove = useCallback(() => {
    setValues([]);
  }, [setValues]);

  return {
    values,
    isActive: values.length > 0,
    toggleValue,
    remove,
  };
}
