import { NavBar, NavLink } from 'lib/navbar';

import HOA from './HOA.svg';

interface Props {
  isDesktop: boolean;
}
// Specify here the dimensions of the logo to be used in the properties
// of the <img> in the shared component
const infoImage = { name: HOA, width: '812', height: '268' };

function Navigation(props: Props) {
  const { isDesktop } = props;

  return (
    <NavBar isDesktop={isDesktop} image={infoImage}>
      <NavLink to="/explore">Explore</NavLink>
      <NavLink to="/search">Search</NavLink>
      {/* <NavLink to="/reconstructions">
        {isTablet ? `3D Reconstructions` : `3D Reconst.`}
      </NavLink> */}
      <NavLink to="/help">Help</NavLink>
    </NavBar>
  );
}

export default Navigation;
