import type {
  GalleryFile,
  ProcessedDataset,
  ProcessedFile,
} from 'lib/api-models';
import { DOI, Files, Gallery, MetaItem } from 'lib/dataset';
import { dateFormat, getMetadataByName } from 'lib/helpers';

import styles from './Metadata.module.css';
import ModalDownload from './ModalDownload';
import NeuroglancerLink from './NeuroglancerLink';
import NeuroglancerWindow from './NeuroglancerWindows';

function Metadata(props: {
  isDesktop: boolean;
  doi: string;
  dataset: ProcessedDataset;
  fileList: ProcessedFile[];
  neuroglancerFile: GalleryFile | undefined;
}) {
  const { isDesktop, doi, dataset, fileList, neuroglancerFile } = props;

  return (
    <>
      <div className={styles.metadataSection}>
        <div className={styles.headingMaster}>
          <h2 className={styles.headingTitle}>Description</h2>
          <p className={styles.headingAbstract}>
            {getMetadataByName(dataset.parameters, 'DOI_abstract')}
          </p>
        </div>
        <ul className={styles.metadataList}>
          <MetaItem label="Users">
            {getMetadataByName(dataset.parameters, 'DOI_users')}
          </MetaItem>
          <MetaItem label="Technique">
            {getMetadataByName(dataset.parameters, 'TOMO_technique')}
          </MetaItem>
          <MetaItem label="Instrument">
            {dataset.instrumentName}, ESRF
            {/* {instrument.facility} */}
          </MetaItem>
          <MetaItem label="DOI">
            <DOI doi={doi} />
          </MetaItem>
          <MetaItem label="Citation">
            Publications using this data <b>must cite the DOI</b> , and are
            encouraged to cite the{' '}
            <a
              className={styles.inlineLink}
              href="https://www.nature.com/articles/s41592-021-01317-x"
              target="_blank"
              rel="noreferrer"
            >
              HiP-CT methods paper
            </a>
            .
          </MetaItem>
        </ul>
      </div>

      {isDesktop && neuroglancerFile && (
        <NeuroglancerWindow resourceId={neuroglancerFile.id} />
      )}

      {!isDesktop && (
        <div className={styles.scienceFiles}>
          <Files doi={doi} files={fileList}>
            {neuroglancerFile && (
              <NeuroglancerLink resourceId={neuroglancerFile.id} />
            )}
            <ModalDownload />
          </Files>
          <Gallery files={dataset.gallery} />
        </div>
      )}

      <div className={styles.metadataSection}>
        <div className={styles.heading}>
          <h2 className={styles.headingTitle}>Patient</h2>
        </div>
        <ul className={styles.metadataList}>
          <MetaItem label="Institute">
            {getMetadataByName(dataset.parameters, 'SamplePatient_institute')}
          </MetaItem>
          <MetaItem label="Number">
            {getMetadataByName(dataset.parameters, 'SamplePatient_number')}
          </MetaItem>
          <MetaItem label="Age">
            {getMetadataByName(dataset.parameters, 'SamplePatient_age')}
          </MetaItem>
          <MetaItem label="Sex">
            {getMetadataByName(dataset.parameters, 'SamplePatient_sex')}
          </MetaItem>
          <MetaItem label="Weight">
            {getMetadataByName(dataset.parameters, 'SamplePatient_weight')}
          </MetaItem>
          <MetaItem label="Height">
            {getMetadataByName(dataset.parameters, 'SamplePatient_size')}
          </MetaItem>
          <MetaItem label="Medical info">
            {getMetadataByName(dataset.parameters, 'SamplePatient_info')}
          </MetaItem>
        </ul>
      </div>
      <div className={styles.metadataSection}>
        <div className={styles.heading}>
          <h2 className={styles.headingTitle}>Sample</h2>
        </div>
        <ul className={styles.metadataList}>
          <MetaItem label="Name">
            {getMetadataByName(dataset.parameters, 'Sample_name')}
          </MetaItem>
          <MetaItem label="Organ">
            {getMetadataByName(dataset.parameters, 'SamplePatient_organ_name')}
          </MetaItem>
          <MetaItem label="Info">
            {getMetadataByName(dataset.parameters, 'Sample_description')}
          </MetaItem>
          <MetaItem label="Preparation">
            {getMetadataByName(
              dataset.parameters,
              'Sample_preparation_description',
            )}
          </MetaItem>
        </ul>
      </div>
      <div className={styles.metadataSection}>
        <div className={styles.heading}>
          <h2 className={styles.headingTitle}>Scan parameters</h2>
        </div>
        <ul className={styles.metadataList}>
          <MetaItem wide label="Start date">
            {dateFormat(getMetadataByName(dataset.parameters, 'startDate'))}
          </MetaItem>
          <MetaItem wide label="SR current">
            {getMetadataByName(dataset.parameters, 'InstrumentSource_current')}
          </MetaItem>
          <MetaItem wide label="ID names">
            {getMetadataByName(dataset.parameters, 'TOMO_idNames')}
          </MetaItem>
          <MetaItem wide label="Scan radix">
            {getMetadataByName(dataset.parameters, 'TOMO_scanRadix')}
          </MetaItem>
          <MetaItem wide label="X step">
            {getMetadataByName(dataset.parameters, 'TOMO_xStep')}
          </MetaItem>
          <MetaItem wide label="X stages">
            {getMetadataByName(dataset.parameters, 'TOMO_xStages')}
          </MetaItem>
          <MetaItem wide label="Y step">
            {getMetadataByName(dataset.parameters, 'TOMO_yStep')}
          </MetaItem>
          <MetaItem wide label="Y stages">
            {getMetadataByName(dataset.parameters, 'TOMO_yStages')}
          </MetaItem>
          <MetaItem wide label="Z step">
            {getMetadataByName(dataset.parameters, 'TOMO_zStep')}
          </MetaItem>
          <MetaItem wide label="Z stages">
            {getMetadataByName(dataset.parameters, 'TOMO_zStages')}
          </MetaItem>
          <MetaItem wide label="Projections">
            {getMetadataByName(dataset.parameters, 'TOMO_projN')}
          </MetaItem>
          <MetaItem wide label="Ref N">
            {getMetadataByName(dataset.parameters, 'TOMO_refN')}
          </MetaItem>
          <MetaItem wide label="Dark N">
            {getMetadataByName(dataset.parameters, 'TOMO_darkN')}
          </MetaItem>
          <MetaItem wide label="Ref on">
            {getMetadataByName(dataset.parameters, 'TOMO_refOn')}
          </MetaItem>
          <MetaItem wide label="Scanning mode">
            {getMetadataByName(dataset.parameters, 'scanType')}
          </MetaItem>
          <MetaItem wide label="Exposure time">
            {getMetadataByName(dataset.parameters, 'TOMO_exposureTime')}
          </MetaItem>
          <MetaItem wide label="Acc. exposure time">
            {getMetadataByName(dataset.parameters, 'TOMO_accExposureTime')}
          </MetaItem>
          <MetaItem wide label="Acc. frames count">
            {getMetadataByName(dataset.parameters, 'TOMO_accFramesCount')}
          </MetaItem>
          <MetaItem wide label="Pixel size">
            {getMetadataByName(dataset.parameters, 'TOMO_pixelSize')}
          </MetaItem>
          <MetaItem wide label="Prop. distance">
            {getMetadataByName(dataset.parameters, 'TOMO_detectorDistance')}
          </MetaItem>
          <MetaItem wide label="Filters">
            {getMetadataByName(
              dataset.parameters,
              'InstrumentAttenuator01_description',
            )}
          </MetaItem>
          <MetaItem wide label="Detected avg. energy">
            {getMetadataByName(dataset.parameters, 'TOMO_energy')}
          </MetaItem>
          <MetaItem wide label="Scan geometry">
            {getMetadataByName(dataset.parameters, 'TOMO_halfAcquisition')}
          </MetaItem>
          <MetaItem wide label="Scan range">
            {getMetadataByName(dataset.parameters, 'TOMO_scanRange')}
          </MetaItem>
          <MetaItem wide label="Sensor name">
            {getMetadataByName(
              dataset.parameters,
              'InstrumentDetector01_description',
            )}
          </MetaItem>
          <MetaItem wide label="Sensor mode">
            {getMetadataByName(
              dataset.parameters,
              'InstrumentDetector01_acquisition_mode',
            )}
          </MetaItem>
          <MetaItem wide label="Sensor pixel size">
            {getMetadataByName(dataset.parameters, 'TOMO_ccdPixelSize')}
          </MetaItem>
          <MetaItem wide label="Magnification">
            {getMetadataByName(dataset.parameters, 'TOMO_magnification')}
          </MetaItem>
          <MetaItem wide label="X pixel num.">
            {getMetadataByName(dataset.parameters, 'TOMO_x_pixel_n')}
          </MetaItem>
          <MetaItem wide label="Y pixel num.">
            {getMetadataByName(dataset.parameters, 'TOMO_y_pixel_n')}
          </MetaItem>
          <MetaItem wide label="Optics type">
            {getMetadataByName(dataset.parameters, 'TOMO_opticsType')}
          </MetaItem>
          <MetaItem wide label="Scintillator">
            {getMetadataByName(dataset.parameters, 'TOMO_scintillator')}
          </MetaItem>
          <MetaItem wide label="Surface dose rate">
            {getMetadataByName(dataset.parameters, 'TOMO_surface_dose')}
          </MetaItem>
          <MetaItem wide label="VOI dose rate">
            {getMetadataByName(dataset.parameters, 'TOMO_voi_dose')}
          </MetaItem>
          <MetaItem wide label="VOI integ. dose">
            {getMetadataByName(dataset.parameters, 'TOMO_total_voi_dose')}
          </MetaItem>
          <MetaItem wide label="Scan time">
            {getMetadataByName(dataset.parameters, 'TOMO_scanTime')}
          </MetaItem>
          <MetaItem wide label="Series time">
            {getMetadataByName(dataset.parameters, 'TOMO_seriesTime')}
          </MetaItem>
        </ul>
      </div>
      <div className={styles.metadataSection}>
        <div className={styles.heading}>
          <h2 className={styles.headingTitle}>Processing</h2>
        </div>
        <ul className={styles.metadataList}>
          <MetaItem wide label="Ref. approach">
            {getMetadataByName(
              dataset.parameters,
              'TOMO_reference_description',
            )}
          </MetaItem>
          <MetaItem wide label="Volume X">
            {getMetadataByName(dataset.parameters, 'TOMO_x_volume')}
          </MetaItem>
          <MetaItem wide label="Volume Y">
            {getMetadataByName(dataset.parameters, 'TOMO_y_volume')}
          </MetaItem>
          <MetaItem wide label="Volume Z">
            {getMetadataByName(dataset.parameters, 'TOMO_z_volume')}
          </MetaItem>
          <MetaItem wide label="32 to 16 bits min.">
            {getMetadataByName(dataset.parameters, 'TOMO_min32to16bits')}
          </MetaItem>
          <MetaItem wide label="32 to 16 bits max.">
            {getMetadataByName(dataset.parameters, 'TOMO_max32to16bits')}
          </MetaItem>
          <MetaItem wide label="JP2 compr. ratio">
            {getMetadataByName(dataset.parameters, 'TOMO_jp2CompressRatio')}
          </MetaItem>
        </ul>
      </div>
    </>
  );
}

export default Metadata;
