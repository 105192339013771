import { Helmet } from 'react-helmet';

import BackToHelp from './BackToHelp';
import styles from './NeuroglancerTutorial.module.css';

function NeuroglancerTutorial() {
  return (
    <>
      <Helmet title="Tutorial - Neuro" />

      <div className={styles.wrapper}>
        <BackToHelp />
        <div className={styles.videoContainer}>
          <h1 className={styles.tutorialTitle}>Guide to Neuroglancer</h1>
          <iframe
            className={styles.videoplayer}
            width="560"
            height="315"
            src="https://www.youtube.com/embed/Yg0ITKw5GiE?si=PVqTHgRy4ewo4D8k"
            title="Video on using Neuroglancer in the Human Organ Atlas"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </div>
      </div>
    </>
  );
}

export default NeuroglancerTutorial;
