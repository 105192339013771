import { assertNonNull } from 'lib/guards';
import styles from 'lib/TableOfContent.module.css';
import { useLocation } from 'wouter';

function ToCFiji() {
  const [, navigate] = useLocation();

  function handleSectionLinkClick(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    const to = event.currentTarget.getAttribute('href');
    assertNonNull(to);
    navigate(`/fiji${to}`);
    document.querySelector(to)?.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <nav className={styles.tocList}>
      <a
        className={styles.linkHelp}
        href="#preamble"
        onClick={handleSectionLinkClick}
      >
        Preamble
      </a>
      <a
        className={styles.linkHelp}
        href="#downloadFiji"
        onClick={handleSectionLinkClick}
      >
        Download FIJI/ImageJ
      </a>
      <a
        className={styles.linkHelp}
        href="#gettingStarted"
        onClick={handleSectionLinkClick}
      >
        Getting started
      </a>
      <a
        className={styles.linkHelp}
        href="#exampleMacro"
        onClick={handleSectionLinkClick}
      >
        Small Macro Example with BoneJ
      </a>
    </nav>
  );
}

export default ToCFiji;
