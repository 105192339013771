import { usePageTracking } from 'lib/hooks';
import { SearchLayout } from 'lib/search-layout';

import { FILTERS_BY_GROUP } from '../filters';
import DatasetCard from './DatasetCard';

interface Props {
  isDesktop: boolean;
}

function SearchPage(props: Props) {
  usePageTracking();

  const { isDesktop } = props;

  return (
    <SearchLayout
      isDesktop={isDesktop}
      Card={DatasetCard}
      filtersByGroup={FILTERS_BY_GROUP}
    />
  );
}

export default SearchPage;
