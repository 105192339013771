import type { PropsWithChildren } from 'react';

import styles from './MetaItem.module.css';

interface Props {
  label: string;
  wide?: boolean;
  narrow?: boolean;
}

function MetaItem(props: PropsWithChildren<Props>) {
  const { label, children, wide, narrow } = props;

  if (children === undefined) {
    return null;
  }

  return (
    <li className={styles.item} data-narrow={narrow || undefined}>
      {wide ? (
        <div
          className={styles.label}
          data-wide
          data-narrow={narrow || undefined}
        >
          {label}
        </div>
      ) : (
        <div className={styles.label} data-narrow={narrow || undefined}>
          {label}
        </div>
      )}

      <div className={styles.metadata} data-narrow={narrow || undefined}>
        {children}
      </div>
    </li>
  );
}

export default MetaItem;
