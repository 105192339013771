import styles from './NoResults.module.css';

function NoResults() {
  return (
    <div className={styles.noresultBox}>
      <h2 className={styles.noresultTitle}>No results</h2>
      <p className={styles.noresultParagraph}>
        Please adjust the search filters
      </p>
    </div>
  );
}

export default NoResults;
