import type { ReactNode } from 'react';

import styles from './PageWrapper.module.css';

function PageWrapper(props: { children: ReactNode }) {
  const { children } = props;

  return <div className={styles.wrapper}>{children}</div>;
}

export default PageWrapper;
