import { useInView } from 'react-intersection-observer';

import { assertEnvVar } from '../guards';
import { trackPlay } from '../helpers';
import styles from './Media.module.css';

const ICATP = import.meta.env.VITE_ICATP;

function Media(props: { sessionId: string; id: string; type: string }) {
  const { sessionId, id, type } = props;
  assertEnvVar(ICATP, 'VITE_ICATP');
  const src = `${ICATP}/resource/${sessionId}/file/download?resourceId=${id}`;

  const { ref, inView } = useInView({ triggerOnce: true });

  if (type === 'image') {
    return (
      // The order of loading="lazy" and src seems to have an importance,
      // similar to this post: https://github.com/sveltejs/svelte/issues/7454
      // but with an other attribute.
      // The rule is then to place loading="lazy" before src in the attributes
      <img className={styles.mediaImage} alt="" loading="lazy" src={src} />
    );
  }

  if (type === 'video') {
    return (
      <video
        className={styles.mediaVideo}
        ref={ref}
        controls
        muted
        loop
        preload="metadata"
        onPlay={() => trackPlay(src)}
      >
        {inView && <source type="video/mp4" src={src} />}
      </video>
    );
  }

  return null;
}

export default Media;
