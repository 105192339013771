import { useNeuroglancerUrl } from 'lib/api-hooks';

import styles from './NeuroglancerWindow.module.css';

interface Props {
  resourceId: string;
}

function NeuroglancerWindow(props: Props) {
  const { resourceId } = props;
  const neuroglancerLink = useNeuroglancerUrl(resourceId);

  return (
    <div className={styles.neuroglancerContainer}>
      <iframe
        className={styles.neuroglancerWindow}
        src={`${neuroglancerLink}`}
        title="neuroglancer-frame"
        allowFullScreen
        allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      />
    </div>
  );
}
export default NeuroglancerWindow;
