import { FiXCircle } from 'react-icons/fi';

import styles from './ClearButton.module.css';

interface Props {
  disabled: boolean;
  onClick: () => void;
  cleanSection?: boolean;
}

function ClearButton(props: Props) {
  const { disabled, onClick, cleanSection } = props;

  return (
    <button
      className={styles.clearButton}
      type="button"
      disabled={disabled}
      aria-label="Clear"
      onClick={onClick}
      data-cleansection={cleanSection || undefined}
    >
      <FiXCircle />
    </button>
  );
}

export default ClearButton;
