import 'react-responsive-modal/styles.css';
import 'lib/styles.css'; // global styles

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { assertNonNull } from 'lib/guards';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

const queryClient = new QueryClient();

const domNode = document.querySelector('#root');
assertNonNull(domNode);

const root = createRoot(domNode);
root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </StrictMode>,
);
