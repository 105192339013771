import { FiDownload } from 'react-icons/fi';

import { getGlobusUrl } from '../helpers';
import styles from '../shared.module.css';

interface Props {
  filePath: string;
  handleClick?: (evt: React.MouseEvent<HTMLAnchorElement>) => void;
}

function GlobusLink(props: Props) {
  const { filePath, handleClick } = props;
  const globusLink = getGlobusUrl(filePath);

  return (
    <a
      className={styles.pill}
      href={`${globusLink}`}
      data-alt2
      target="_blank"
      rel="noreferrer"
      onClick={handleClick}
    >
      <span />
      <span>Download files with Globus</span>
      <span>
        <FiDownload />
      </span>
    </a>
  );
}
export default GlobusLink;
