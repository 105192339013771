import { Boundary } from 'lib/boundary';
import type { ReactNode } from 'react';
import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Route, useLocation } from 'wouter';

import styles from './ExploreLayout.module.css';

interface Layer {
  title: string;
  component: ReactNode;
}

interface Props {
  layerList: Record<string, Layer>;
}

function ExploreLayout(props: Props) {
  const [location] = useLocation();

  const ref_second_layer = useRef<HTMLDivElement>(null);
  const ref_third_layer = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const location_split = location.split('/');
    if (location_split.length === 2 && location_split[1] !== '') {
      ref_second_layer.current?.scrollIntoView({ behavior: 'smooth' });
    }
    if (location_split.length === 3) {
      ref_third_layer.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  const { layerList } = props;

  return (
    <>
      <Helmet>
        <title>Explore</title>
      </Helmet>

      <div className={styles.wrapperFirstLayer}>
        <h1 className={styles.sectionTitle}>{layerList.firstLayer.title}</h1>
        {layerList.firstLayer.component}
      </div>

      <Route path="/:firstLayer" nest>
        <div ref={ref_second_layer} className={styles.wrapper}>
          <h1 className={styles.sectionTitle}>{layerList.secondLayer.title}</h1>
          <Boundary resetKeys={[location]}>
            {layerList.secondLayer.component}
          </Boundary>
        </div>
      </Route>

      <Route path="/:firstLayer/:secondLayer">
        <div ref={ref_third_layer} className={styles.wrapper}>
          <h1 className={styles.sectionTitle}>{layerList.thirdLayer.title}</h1>
          <Boundary resetKeys={[location]}>
            {layerList.thirdLayer.component}
          </Boundary>
        </div>
      </Route>
    </>
  );
}

export default ExploreLayout;
