import type { ReactNode } from 'react';

import ClearButton from './ClearButton';
import styles from './FilterBox.module.css';

interface Props {
  title: string | boolean;
  showTitle: boolean;
  isActive: boolean;
  onClear?: () => void;
  children: ReactNode;
}

function FilterBox(props: Props) {
  const { title, showTitle = true, isActive, onClear, children } = props;

  return (
    <div className={styles.filterBox}>
      {showTitle && (
        <h3 className={styles.titleBox} data-active={isActive || undefined}>
          {title}
        </h3>
      )}

      {children}

      {onClear && (
        <ClearButton
          disabled={!isActive}
          onClick={() => onClear()}
          cleanSection
        />
      )}
    </div>
  );
}

export default FilterBox;
