import { useDebouncedCallback } from '@react-hookz/web';
import { useState } from 'react';

import ClearButton from './ClearButton';
import FilterBox from './FilterBox';
import { OPERATORS, useQueryParam } from './router-utils';
import styles from './TextInput.module.css';

interface Props {
  name: string;
  label: string | boolean;
  placeholder?: string;
}

function TextInput(props: Props) {
  const { name, label, placeholder } = props;

  const param = useQueryParam(name);

  const [inputValue, setInputValue] = useState(param.value || '');

  const handleChange = useDebouncedCallback(
    (val: string) => param.setValue(`${OPERATORS.like}${val}`),
    [param],
    500,
  );

  return (
    <FilterBox
      title={label}
      showTitle={label !== false}
      isActive={param.isActive}
    >
      <div className={styles.textContainer}>
        <input
          className={styles.textInput}
          type="text"
          value={inputValue}
          placeholder={placeholder}
          aria-label="Pathology"
          onChange={(evt) => {
            const { value } = evt.target;
            setInputValue(value);
            handleChange(value);
          }}
        />

        <ClearButton
          disabled={!inputValue}
          onClick={() => {
            setInputValue('');
            param.remove();
          }}
        />
      </div>
    </FilterBox>
  );
}

export default TextInput;
