import { useIcatSessionId } from 'lib/api-hooks';
import type { ProcessedDataset } from 'lib/api-models';
import { assertDefined, assertEnvVar } from 'lib/guards';
import {
  getMetadataByName,
  sampleNameFormatter,
  sampleNameSplitter,
} from 'lib/helpers';
import { Link, useRoute } from 'wouter';

import styles from './OrganCard.module.css';

interface Props {
  dataset: ProcessedDataset;
  patientName: string;
}

const ICATP = import.meta.env.VITE_ICATP;

function OrganCard(props: Props) {
  assertEnvVar(ICATP, 'VITE_ICATP');
  const { dataset, patientName } = props;

  const sessionId = useIcatSessionId();

  const galleryFilePaths = getMetadataByName(
    dataset.parameters,
    'ResourcesGalleryFilePaths',
  );
  assertDefined(galleryFilePaths);
  const resources = galleryFilePaths.split(',');

  const galleryIds = getMetadataByName(dataset.parameters, 'ResourcesGallery');
  assertDefined(galleryIds);
  const resourcesId = galleryIds.split(' ');

  const sampleIndex = resources.findIndex(
    (r) => r.includes('sample_') && !r.endsWith('.tif'),
  );
  const thumbnailId = resourcesId[sampleIndex];

  const sample = sampleNameSplitter(dataset.sampleName, patientName);

  const [isActive] = useRoute(`/${sample}`);

  return (
    <Link
      key={dataset.sampleName}
      to={`/${encodeURIComponent(sample)}`}
      className={isActive ? styles.activeOrganCard : styles.organCard}
    >
      <h2 className={styles.organCardTitle}>
        {sampleNameFormatter(dataset.sampleName, patientName)}
      </h2>
      {thumbnailId && (
        <div className={styles.thumbnail}>
          <img
            className={styles.organThumbnail}
            alt="3D rendering of the sample"
            src={`${ICATP}/resource/${sessionId}/file/download?resourceId=${thumbnailId}`}
          />
        </div>
      )}
    </Link>
  );
}

export default OrganCard;
