import { FiArrowLeft } from 'react-icons/fi';
import { Link } from 'wouter';

import styles from './BackToHelp.module.css';

function BackToHelp() {
  return (
    <Link className={styles.backButton} to="~/help">
      <FiArrowLeft className={styles.backArrow} />
      <span>Back to Help</span>
    </Link>
  );
}

export default BackToHelp;
