import { useSuspenseQuery } from '@tanstack/react-query';

import {
  fetchAllDatasets,
  fetchDatasetsById,
  fetchDatasetsByQuery,
  fetchDOI,
  fetchFileList,
  fetchIcatSession,
  fetchInvestigationIds,
  fetchInvestigationParameters,
  fetchNeuroglancerInfo,
  fetchValuesParameters,
} from './api-fetchers';
import type {
  InvestigationParameter,
  ProcessedDataset,
  ProcessedFileList,
  ValuesParameter,
} from './api-models';
import { QueryKey } from './api-models';
import { assertEnvVar } from './guards';

const DOMAIN_NEUROGLANCER = import.meta.env.VITE_NEUROGLANCER;
const PROJECT_NAME = import.meta.env.VITE_PROJECT_NAME;

export function useIcatSessionId(): string {
  return useSuspenseQuery({
    queryKey: [QueryKey.IcatSessionId],
    queryFn: fetchIcatSession,
    staleTime: Infinity,
    refetchInterval: 60 * 60 * 1000, // 60 min
  }).data;
}

export function useInvestigationIds(): string {
  const sessionId = useIcatSessionId();

  return useSuspenseQuery({
    queryKey: [QueryKey.InvestigationIds],
    queryFn: async () => fetchInvestigationIds(sessionId),
    staleTime: Infinity,
    refetchInterval: 60 * 60 * 1000, // 60 min
  }).data;
}

export function useAllDatasets(
  parametersInfo?: Record<string, string>,
): ProcessedDataset[] {
  const sessionId = useIcatSessionId();
  const investigationIds = useInvestigationIds();

  return useSuspenseQuery({
    queryKey: [
      QueryKey.AllDatasets,
      sessionId,
      investigationIds,
      parametersInfo,
    ],
    queryFn: async () =>
      fetchAllDatasets(sessionId, investigationIds, parametersInfo),
    staleTime: Infinity,
  }).data;
}

export function useDatasetsById(datasetIds: string[]): ProcessedDataset[] {
  const sessionId = useIcatSessionId();

  return useSuspenseQuery({
    queryKey: [QueryKey.Datasets, sessionId, datasetIds],
    queryFn: async () => fetchDatasetsById(sessionId, datasetIds),
    staleTime: Infinity,
  }).data;
}

export function useDatasetsByQuery(queryParam: string): ProcessedDataset[] {
  const sessionId = useIcatSessionId();
  const investigationIds = useInvestigationIds();

  return useSuspenseQuery({
    queryKey: [QueryKey.Datasets, sessionId, investigationIds, queryParam],
    queryFn: async () =>
      fetchDatasetsByQuery(sessionId, investigationIds, queryParam),
    staleTime: Infinity,
  }).data;
}

export function useFileList(datasetId: string): ProcessedFileList {
  const sessionId = useIcatSessionId();

  return useSuspenseQuery({
    queryKey: [QueryKey.FileList, sessionId, datasetId],
    queryFn: async () => fetchFileList(sessionId, datasetId),
    staleTime: Infinity,
  }).data;
}

export function useInvestigationParameters(
  queryParam: Record<string, string>,
): InvestigationParameter[] {
  const sessionId = useIcatSessionId();
  const investigationIds = useInvestigationIds();

  return useSuspenseQuery({
    queryKey: [
      QueryKey.InvestigationParameters,
      sessionId,
      investigationIds,
      queryParam,
    ],
    queryFn: async () =>
      fetchInvestigationParameters(sessionId, investigationIds, queryParam),
    staleTime: Infinity,
  }).data;
}

export function useValuesParameters(
  queryParam: Record<string, string>,
): ValuesParameter[] {
  const sessionId = useIcatSessionId();
  const investigationIds = useInvestigationIds();

  return useSuspenseQuery({
    queryKey: [
      QueryKey.ValuesParameters,
      sessionId,
      investigationIds,
      queryParam,
    ],
    queryFn: async () =>
      fetchValuesParameters(sessionId, investigationIds, queryParam),
    staleTime: Infinity,
  }).data;
}

export function useMetadataValues(name: string) {
  assertEnvVar(PROJECT_NAME, 'VITE_PROJECT_NAME');
  return useValuesParameters({
    name,
    parameters: `project_name~eq~${PROJECT_NAME}`,
  }).flatMap((p) => p.values);
}

export function useMultipleMetadataParams(
  names: Record<string, string>,
  query_params: string,
) {
  assertEnvVar(PROJECT_NAME, 'VITE_PROJECT_NAME');
  const results = useValuesParameters({
    name: Object.values(names).join(','),
    parameters: `project_name~eq~${PROJECT_NAME},${query_params}`,
  });
  return Object.fromEntries(
    Object.keys(names).map((name) => [
      name,
      results.find((p) => p.name.includes(name))?.values[0],
    ]),
  );
}

export function useDOI(datasetId: number): string {
  const sessionId: string = useIcatSessionId();

  const { data } = useSuspenseQuery({
    queryKey: [QueryKey.DOI, sessionId, datasetId],
    queryFn: async () => fetchDOI(sessionId, datasetId),
    staleTime: Infinity,
  });

  return data;
}

export function useNeuroglancerUrl(resourceId: string) {
  const sessionId: string = useIcatSessionId();
  assertEnvVar(DOMAIN_NEUROGLANCER, 'VITE_NEUROGLANCER');

  const { data } = useSuspenseQuery({
    queryKey: [QueryKey.Neuroglancer, sessionId, resourceId],
    queryFn: async () => fetchNeuroglancerInfo(sessionId, resourceId),
    staleTime: Infinity,
  });

  const neuroInfos = encodeURIComponent(data);
  return `${DOMAIN_NEUROGLANCER}/#!${neuroInfos}`;
}
