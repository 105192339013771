import type { ReactNode } from 'react';
import { Link, useRoute } from 'wouter';

import styles from './NavLink.module.css';

interface Props {
  to: string;
  children: ReactNode;
  home?: boolean;
  state?: Record<string, string>;
}

function NavLink(props: Props) {
  const { to, children, home, state } = props;

  const [match] = useRoute(`${to}/*?`);

  return (
    <Link
      to={to}
      className={styles.link}
      state={state}
      data-active={match || undefined}
      data-home={home || undefined}
    >
      {children}
    </Link>
  );
}

export default NavLink;
