import { create } from 'zustand';

type NumResults = number | undefined;

interface ModalNumResults {
  numResults: NumResults;
  setNumResults: (numResults: NumResults) => void;
}

export const useAppStore = create<ModalNumResults>((set) => ({
  numResults: undefined,
  setNumResults: (numResults) => {
    set({ numResults });
  },
}));
