import PulseLoader from 'react-spinners/PulseLoader';

import styles from './Spinner.module.css';

function Spinner() {
  return (
    <div className={styles.spinner}>
      <PulseLoader color="currentColor" />
    </div>
  );
}

export default Spinner;
