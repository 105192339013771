import { useIcatSessionId } from '../api-hooks';
import type { GalleryFile } from '../api-models';
import styles from './Gallery.module.css';
import Media from './Media';

function Gallery(props: { files: GalleryFile[] }) {
  const { files } = props;
  const sessionId = useIcatSessionId();

  return (
    <div className={styles.gallery}>
      {files.map((f) => (
        <Media key={f.id} sessionId={sessionId} {...f} />
      ))}
    </div>
  );
}

export default Gallery;
