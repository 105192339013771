export enum QueryKey {
  IcatSessionId = 'icat-session-id',
  InvestigationIds = 'investigation-ids',
  AllDatasets = 'all-datasets',
  Datasets = 'datasets',
  FileList = 'file-list',
  DOI = 'doi',
  InvestigationParameters = 'investigation-parameters',
  ValuesParameters = 'values-parameters',
  Neuroglancer = 'neuroglancer',
}

export interface IcatSessionResponse {
  sessionId: string;
}

export type InvestigationResponse = Investigation[];
export type DatasetsResponse = Dataset[];
export type DataCollectionResponse = DataCollection[];
export type FileListResponse = FileInfo[];
export type InvestigationParametersResponse = InvestigationParameter[];
export type ValuesParametersResponse = ValuesParameter[];

export interface Parameter {
  name: string;
  value: string;
  id: number;
  units: string;
  datasetId: number;
}

export interface Dataset {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  location: string;
  investigation: unknown;
  type: string;
  sampleName: string;
  sampleId: number;
  parameters: Parameter[];
  instrumentName: string;
  meta: unknown;
}

interface DataCollection {
  doi: string;
}

export interface Investigation {
  name: string;
  startDate: string;
  endDate: string;
  id: number;
  doi: string;
  title: string;
  visitId: string;
  releaseDate: string;
  summary: string;
  parameters: unknown;
  instrument: unknown;
  investigationUsers: unknown;
  meta: unknown;
  type: unknown;
}

export interface InvestigationParameter {
  name: string;
  value: string;
  id: number;
  units: string;
  datasetId: number;
  sampleId: number;
  sampleName: string;
}

export interface ValuesParameter {
  name: string;
  values: string[];
}
// ----------------------------------------

export interface GalleryFile {
  id: string;
  name: string;
  type: string;
}

export interface ProcessedDataset {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  location: string;
  investigation: unknown;
  type: string;
  sampleName: string;
  sampleId: number;
  parameters: Map<string, Parameter>;
  galleryIds: string[];
  gallery: GalleryFile[];
  instrumentName: string;
  meta: unknown;
}

// ----------------------------------------

export interface DatasetInDatafile {
  id: number;
  createId: string;
  createTime: string;
  modId: string;
  modTime: string;
  complete: boolean;
  dataCollectionDatasets: unknown;
  datafiles: unknown;
  datasetInstruments: unknown;
  datasetTechniques: unknown;
  fileCount: number;
  fileSize: number;
  location: string;
  name: string;
  parameters: unknown;
  startDate: string;
  endDate: string;
}

export interface Datafile {
  id: number;
  createId: string;
  createTime: string;
  modId: string;
  modTime: string;
  dataCollectionDatafiles: unknown;
  dataset: DatasetInDatafile;
  destDatafiles: unknown;
  fileSize: number;
  location: string;
  name: string;
  parameters: unknown;
  sourceDatafiles: unknown;
}

export interface FileInfo {
  Datafile: Datafile;
  meta: unknown;
}

export interface ProcessedFile {
  id: number;
  datasetId: number;
  fileSize: number;
  name: string;
  location: string;
  ext: string;
  shortName: string;
}

export interface ProcessedFileList {
  files: ProcessedFile[];
}
