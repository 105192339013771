export const CATALOG_UNIT: Record<string, string> = {
  SamplePatient_age: 'yo',
  SamplePatient_weight: 'kg',
  SamplePatient_size: 'cm',
  InstrumentSource_current: 'mA',
  TOMO_zStep: 'mm',
  TOMO_exposureTime: 's',
  TOMO_accExposureTime: 's',
  TOMO_pixelSize: 'um',
  TOMO_detectorDistance: 'um',
  TOMO_energy: 'keV',
  TOMO_scanRange: 'deg',
  TOMO_ccdPixelSize: 'um',
  TOMO_surface_dose: 'Gy/s',
  TOMO_voi_dose: 'Gy/s',
  TOMO_total_voi_dose: 'kGy',
  TOMO_scanTime: 'min',
  TOMO_seriesTime: 'h',
};
