import { useIcatSessionId } from 'lib/api-hooks';
import { assertEnvVar } from 'lib/guards';
import { trackLink } from 'lib/helpers';
import { usePageTracking, useScrollToTop } from 'lib/hooks';
import { FiTwitter } from 'react-icons/fi';
import { Link } from 'wouter';

import styles from './HomePage.module.css';

const HOME_VIDEO_ID = import.meta.env.VITE_HOME_VIDEO_ID;
const ICATP = import.meta.env.VITE_ICATP;

function HomePage() {
  useScrollToTop();
  usePageTracking();

  assertEnvVar(HOME_VIDEO_ID, 'VITE_HOME_VIDEO_ID');
  assertEnvVar(ICATP, 'VITE_ICATP');
  const sessionId = useIcatSessionId();

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <h1 className={styles.headTitle}>
          Welcome to the Human&nbsp;Organ&nbsp;Atlas
        </h1>
        <p>
          The Human Organ Atlas uses{' '}
          <strong>Hierarchical Phase-Contrast Tomography</strong> to image human
          anatomy from the micron to whole organ scale.
        </p>
        <p>
          Histology images cells and other structures with sub-micron accuracy
          but only on small biopsies of tissue from an organ. Clinical CT and
          MRI scans image whole organs, but with a resolution only down to just
          below a millimetre.{' '}
          <abbr title="Hierarchical Phase-Contrast Tomography">HiP-CT</abbr>{' '}
          bridges these scales in 3D, imaging whole intact organs with 20 μm
          resolution, and specific volumes of interest down to the single micron
          scale.
        </p>
        <p>
          We hope this open access Atlas will act as a reference to provide new
          insights into our biological makeup in health and disease. To stay up
          to date, <span>follow</span>{' '}
          <a
            className={styles.inlinePill}
            href="https://twitter.com/hip_ct"
            target="_blank"
            rel="noreferrer"
          >
            @HiP-CT
            <FiTwitter />
          </a>
        </p>
      </div>

      <div className={styles.section}>
        <div>
          <video
            controls
            muted
            loop
            preload="metadata"
            className={styles.videoplayer}
          >
            <source
              type="video/mp4"
              src={`${ICATP}/resource/${sessionId}/file/download?resourceId=${HOME_VIDEO_ID}`}
            />
          </video>
          <figcaption>
            HiP-CT imaging and 3D reconstruction of a{' '}
            <Link
              href="/datasets/572252538"
              className={styles.inlineLink}
              state={{ backLabel: 'home' }}
            >
              complete brain from the donor LADAF-2020-31
            </Link>
            . More videos can be viewed on the{' '}
            <a
              className={styles.inlineLink}
              href="https://www.youtube.com/channel/UC5yU8BCXUMeSi6-Ya3mR7mQ"
              target="_blank"
              rel="noreferrer"
              onClick={(evt) => trackLink(evt)}
            >
              HiP-CT YouTube channel
            </a>
            .
          </figcaption>
        </div>
      </div>

      <div className={styles.section}>
        <div>
          <h2 className={styles.sectionTitle}>Team</h2>
          <ul className={styles.listTeam}>
            <li>
              <a
                className={styles.inlineLink}
                href="https://mecheng.ucl.ac.uk/hip-ct/"
                target="_blank"
                rel="noreferrer"
              >
                UCL
              </a>
              , London, England:{' '}
              <strong>
                Peter D Lee, Claire Walsh, David Stansby, Joseph Brunet, Theresa
                Urban
              </strong>
            </li>
            <li>
              <a
                className={styles.inlineLink}
                href="http://www.esrf.eu/"
                target="_blank"
                rel="noreferrer"
              >
                ESRF
              </a>
              , Grenoble, France:{' '}
              <strong>
                Paul Tafforeau, Hector Dejea I Velardo, Joanna Purzycka,
                Guillaume Gaisne, Axel Bocciarelli, Marjolaine Bodin
              </strong>
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>References</h2>
        <p>
          Walsh, C.L., Tafforeau, P., Wagner, W.L. <em>et al.</em> Imaging
          intact human organs with local resolution of cellular structures using
          hierarchical phase-contrast tomography. <em>Nat Methods</em> (2021).{' '}
          <a
            className={styles.inlineLink}
            href="https://doi.org/10.1038/s41592-021-01317-x"
            target="_blank"
            rel="noreferrer"
          >
            https://doi.org/10.1038/s41592-021-01317-x
          </a>
        </p>
        <p>
          <a
            className={styles.inlineLink}
            href="https://mecheng.ucl.ac.uk/HOAHub/"
            target="_blank"
            rel="noreferrer"
          >
            HOAHub
          </a>{' '}
          is the wider project that organises and manages data collection for
          the Human Organ Atlas.
        </p>
      </div>

      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Funding</h2>
        <p>
          This project has been made possible by funding from the{' '}
          <a
            className={styles.inlineLink}
            href="https://www.esrf.fr/"
            target="_blank"
            rel="noreferrer"
          >
            European Synchrotron Radiation Facility (ESRF)
          </a>
          , the{' '}
          <a
            className={styles.inlineLink}
            href="https://chanzuckerberg.com"
            target="_blank"
            rel="noreferrer"
          >
            Chan Zuckerberg Initiative
          </a>
          , a donor-advised fund of the Silicon Valley Community Foundation, the{' '}
          <a
            className={styles.inlineLink}
            href="https://www.ukaachen.de/kliniken-institute/institut-fuer-pathologie/register-covid-19-obduktionen/register-vorstellung/"
            target="_blank"
            rel="noreferrer"
          >
            German Registry of COVID-19 Autopsies (DeRegCOVID)
          </a>
          , supported by the German Federal Ministry of Health, The UK{' '}
          <a
            className={styles.inlineLink}
            href="https://www.ukri.org/councils/mrc/"
            target="_blank"
            rel="noreferrer"
          >
            Medical Research Council (MRC)
          </a>
          , the{' '}
          <a
            className={styles.inlineLink}
            href="https://raeng.org.uk/"
            target="_blank"
            rel="noreferrer"
          >
            Royal Academy of Engineering
          </a>
          , and the{' '}
          <a
            className={styles.inlineLink}
            href="https://wellcome.org/"
            target="_blank"
            rel="noreferrer"
          >
            Wellcome Trust
          </a>
          .
        </p>

        <div className={styles.fundingsLogos}>
          <a
            className={styles.logoCardLink}
            href="https://mecheng.ucl.ac.uk/hip-ct/"
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.logoCard}>
              <img
                src="logos/ucl.png"
                srcSet="logos/ucl_2x.png 2x"
                alt="Logo UCL"
                width="292"
                height="100"
              />
            </div>
          </a>
          <a
            className={styles.logoCardLink}
            href="https://www.esrf.fr/"
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.logoCard}>
              <img
                src="logos/esrf.png"
                srcSet="logos/esrf_2x.png 2x"
                alt="Logo ESRF"
                width="91"
                height="100"
              />
            </div>
          </a>
          <a
            className={styles.logoCardLink}
            href="https://chanzuckerberg.com"
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.logoCard}>
              <img
                src="logos/czi.png"
                srcSet="logos/czi_2x.png 2x"
                alt="Logo CZI"
                width="197"
                height="100"
              />
            </div>
          </a>
        </div>
      </div>

      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Acknowledgements</h2>
        <p className={styles.acknowledgementParagraph}>
          The development of this portal has been done as part of the{' '}
          <a
            className={styles.inlineLink}
            href="https://www.panosc.eu/"
            target="_blank"
            rel="noreferrer"
          >
            PaNOSC project.
          </a>
        </p>
        <div className={styles.acknowledgementBox}>
          <img
            className={styles.europeanFlag}
            src="logos/Flag_of_Europe.png"
            alt="European flag"
          />
          <p className={styles.acknowledgementParagraph}>
            PaNOSC has received funding from the European Union’s{' '}
            <a
              className={styles.inlineLink}
              href="https://ec.europa.eu/programmes/horizon2020/en/what-horizon-2020"
              target="_blank"
              rel="noreferrer"
            >
              Horizon 2020
            </a>{' '}
            research and innovation programme under grant agreement No. 823852.
          </p>
        </div>
        <p>
          The following people were involved in the development: Paul Tafforeau,
          Alejandro De Maria Antolinos, Axel Bocciarelli, Marjolaine Bodin and
          Andrew Götz from the ESRF, Jiří Majer from ELI, as well as the broader
          PaNOSC and ICAT communities.
        </p>
      </div>
    </div>
  );
}

export default HomePage;
