import { usePageTracking, useScrollToTop } from 'lib/hooks';
import { Helmet } from 'react-helmet';
import { Link, Route } from 'wouter';

import FijiTutorial from './Fiji/FijiTutorial';
import styles from './HelpPage.module.css';
import NeuroglancerTutorial from './NeuroglancerTutorial';

interface Props {
  isDesktop: boolean;
}

function HelpPage(props: Props) {
  useScrollToTop();
  usePageTracking();

  const { isDesktop } = props;
  return (
    <>
      <Helmet>
        <title>Help</title>
      </Helmet>

      <Route path="/fiji" nest>
        <FijiTutorial isDesktop={isDesktop} />
      </Route>

      <Route path="/neuroglancer">
        <NeuroglancerTutorial />
      </Route>

      <Route path="/">
        <div className={styles.wrapper}>
          <h1 className={styles.title}>Tutorials</h1>
          <div className={styles.tutorialContainer}>
            <Link className={styles.tutorialCard} to="/neuroglancer">
              Neuroglancer tutorial
              <img
                src="logos/neuroglancer.png"
                alt="Logo of the Neuroglancer software"
                width="200"
                height="100"
              />
            </Link>
            <Link className={styles.tutorialCard} to="/fiji">
              Fiji tutorial
              <img
                src="logos/logo_FIJI.png"
                alt="Logo of the FIJI software"
                width="100"
                height="100"
              />
            </Link>
          </div>
          <div>
            <h1 className={styles.title}>FAQ</h1>
            <details>
              <summary className={styles.sectionTitle}>
                How do I download HOA data?
              </summary>
              <p className={styles.sectionParagraph}>
                Once you have found the dataset you want to download, you will
                see a list of files displayed like this:
              </p>
              <div>
                <img
                  className={styles.image}
                  src="help/downloads.png"
                  alt="Screenshot of a list of download links as it may appear on a dataset page"
                />
              </div>
              <p className={styles.sectionParagraph}>
                You have two options for downloading the files:
              </p>
              <ul>
                <li>
                  <h3 className={styles.subsectionTitle}>
                    Download individual files with HTTP
                  </h3>
                  <p className={styles.sectionParagraph}>
                    This is the simplest solution. Download the file you are
                    interested in by simply clicking on the name of the file.
                    This will start downloading the file using the standard web
                    protocol HTTP, which requires no extra tools to use. The
                    downside of this approach is that the file transfer protocol
                    is not reliable for big files and can fail after a few
                    gigabytes depending on your internet connection. In case of
                    failure, your browser will indicate this in the downloads
                    box or toolbar &mdash; e.g. in Firefox, if you click on the{' '}
                    <em>Downloads</em> button, you may see something like this:
                  </p>
                  <div>
                    <img
                      className={styles.image}
                      src="help/firefox-retry.png"
                      alt="Screenshot of a failed download in Firefox, with a retry button on the right-hand side"
                    />
                  </div>
                  <p className={styles.sectionParagraph}>
                    In this case, you can continue downloading the file by
                    clicking on the retry button (i.e. the clockwise arrow). The
                    download will resume from where it failed. You may have to
                    repeat this process multiple times for very large files
                    (i.e. tens of gigabytes). A more efficient and reliable way
                    to download large files as well as multiple files at once is
                    to use Globus (see below).
                  </p>
                </li>

                <li>
                  <h3 className={styles.subsectionTitle}>
                    Download multiple files with Globus
                  </h3>
                  <p className={styles.sectionParagraph}>
                    This is also the best option for downloading large datasets
                    reliably.{' '}
                    <a
                      className={styles.inlineLink}
                      href="https://www.globus.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Globus
                    </a>{' '}
                    is a service for downloading and transferring files. In
                    order to use Globus to download files to your computer, you
                    first need to{' '}
                    <a
                      className={styles.inlineLink}
                      href="https://www.globus.org/globus-connect-personal"
                      target="_blank"
                      rel="noreferrer"
                    >
                      install Globus Connect Personal
                    </a>
                    . Once installed, start the software, and log in to create a{' '}
                    <a
                      className={styles.inlineLink}
                      href="https://docs.globus.org/faq/globus-connect-endpoints/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      local endpoint
                    </a>
                    . In the search box, type "Human Organ Atlas" to find the
                    atlas' public endpoint:
                  </p>
                  <div>
                    <img
                      className={styles.image}
                      src="help/endpoint-results.png"
                      alt="Screenshot showing the Human Organ Atlas endpoint search result"
                    />
                  </div>
                  <p className={styles.sectionParagraph}>
                    Click on the endpoint and then click on{' '}
                    <em>Open in File Manager</em>, you should see all the
                    datasets organised by patient and organ. Now select the
                    dataset(s) you want to download. Note that you can select
                    all the files in the current folder from the file manager's
                    top bar:
                  </p>
                  <div>
                    <img
                      className={styles.image}
                      src="help/download-all.png"
                      alt="Screenshot showing the Globus file manager's top bar with the checkbox to select all files in the current folder"
                    />
                  </div>
                  <p className={styles.sectionParagraph}>
                    Select the directory in which you want the data to be
                    downloaded and start the file transfer by clicking on the{' '}
                    <em>Start</em> button. Globus will restart the transfer
                    automatically in case of failure and inform you once it is
                    complete.
                  </p>
                  <p className={styles.sectionParagraph}>
                    From a dataset's page, you can go directly to the dataset's
                    folder on Globus by clicking on the{' '}
                    <em>Download with Globus</em> button:
                  </p>
                  <div>
                    <img
                      className={styles.image}
                      src="help/globus-download.png"
                      alt="Screenshot showing a Globus download link on a dataset page"
                    />
                  </div>
                  <p className={styles.sectionParagraph}>
                    In case of problems, please email the ESRF data managers at{' '}
                    <a
                      className={styles.inlineLink}
                      href="mailto:dataportalrequests@esrf.fr"
                      target="_blank"
                      rel="noreferrer"
                    >
                      dataportalrequests@esrf.fr
                    </a>
                    .
                  </p>
                </li>
              </ul>
            </details>

            <details>
              <summary className={styles.sectionTitle}>
                What if the downloaded data is corrupt ?
              </summary>
              <p className={styles.sectionParagraph}>
                If you have downloaded a ZIP file and your operating system
                cannot open it because it is corrupt, then most likely the
                download was performed with HTTP. Use Globus as explained above
                to try to download the ZIP file again in a more reliable way.
              </p>
            </details>

            <details>
              <summary className={styles.sectionTitle}>
                What data format is the HOA data?
              </summary>
              <p className={styles.sectionParagraph}>
                The processed data are made available as{' '}
                <a
                  className={styles.inlineLink}
                  href="https://jpeg.org/jpeg2000/"
                  target="_blank"
                  rel="noreferrer"
                >
                  JPEG2000
                </a>{' '}
                images. Some of the datasets of complete organs also include MP4
                videos of the reconstructed data.
              </p>
            </details>

            <details>
              <summary className={styles.sectionTitle}>
                Under what license is HOA data made available?
              </summary>
              <p className={styles.sectionParagraph}>
                The data are made available under the Creative Commons
                Attribution CC BY 4.0 license. This means the data are
                accessible by all for re-use as long as the publication and DOI
                are cited when they are re-used and/or new publications are
                published which have used the data.
              </p>
            </details>

            <details>
              <summary className={styles.sectionTitle}>
                How should I cite HOA data?
              </summary>
              <p className={styles.sectionParagraph}>
                Any publications or re-use of the data from the Human Organ
                Atlas must cite the following publication and DOI:
              </p>
              <p className={styles.sectionParagraph}>
                Walsh, C.L., Tafforeau, P., Wagner, W.L. <em>et al.</em> Imaging
                intact human organs with local resolution of cellular structures
                using hierarchical phase-contrast tomography.{' '}
                <em>Nat Methods</em> (2021).{' '}
                <a
                  className={styles.inlineLink}
                  href="https://doi.org/10.1038/s41592-021-01317-x"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://doi.org/10.1038/s41592-021-01317-x
                </a>
              </p>
            </details>

            <details>
              <summary className={styles.sectionTitle}>
                How do I search for HOA data?
              </summary>
              <p className={styles.sectionParagraph}>
                Click on the <em>Search</em> tab and select the filter settings
                you are interested in to find the datasets which match the
                filters.
              </p>
            </details>

            <details>
              <summary className={styles.sectionTitle}>
                Who can I contact if I have questions or problems?
              </summary>
              <ul>
                <li>
                  For technical issues with downloading the data or using the
                  data portal, please email{' '}
                  <a
                    className={styles.inlineLink}
                    href="mailto:dataportalrequests@esrf.fr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    dataportalrequests@esrf.fr
                  </a>
                  .
                </li>
                <li>
                  For questions about the data and how they were processed,
                  please contact the authors of the publication in Nature
                  Methods (see above).
                </li>
              </ul>
            </details>
          </div>
        </div>
      </Route>
    </>
  );
}

export default HelpPage;
