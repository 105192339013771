import { useMultipleMetadataParams } from 'lib/api-hooks';
import { CATALOG_UNIT } from 'lib/unit-catalog';
import { FiUser } from 'react-icons/fi';
import { Link, useRoute } from 'wouter';

import styles from './PatientCard.module.css';

interface Props {
  patient: string;
}

const NAMES = {
  sex: 'SamplePatient_sex',
  age: 'SamplePatient_age',
  size: 'SamplePatient_size',
  weight: 'SamplePatient_weight',
  info: 'SamplePatient_info',
};

function PatientCard(props: Props) {
  const { patient } = props;

  const query_param = `SamplePatient_number~eq~${patient}`;

  const patientInfos = useMultipleMetadataParams(NAMES, query_param);

  const [isActive] = useRoute(`/${patient}/*?`);

  return (
    <Link
      className={isActive ? styles.activePatientCard : styles.patientCard}
      to={`/${encodeURIComponent(patient)}`}
    >
      <div className={styles.cardTitle}>
        <div className={styles.fiUser}>
          <FiUser />
        </div>
        <h2 className={styles.patientTitle}>{patient}</h2>
      </div>
      <div className={styles.cardInfo} data-keywords>
        {patientInfos.sex && (
          <div className={styles.infoPill}>
            {patientInfos.sex} {CATALOG_UNIT[NAMES.sex]}
          </div>
        )}
        {patientInfos.age && (
          <div className={styles.infoPill}>
            {patientInfos.age} {CATALOG_UNIT[NAMES.age]}
          </div>
        )}
        {patientInfos.weight && (
          <div className={styles.infoPill}>
            {patientInfos.weight} {CATALOG_UNIT[NAMES.weight]}
          </div>
        )}
        {/* eslint-disable-next-line unicorn/explicit-length-check */}
        {patientInfos.size && (
          <div className={styles.infoPill}>
            {patientInfos.size} {CATALOG_UNIT[NAMES.size]}
          </div>
        )}
      </div>
      {patientInfos.info && (
        <p className={styles.patientAbstract}>
          {patientInfos.info} {CATALOG_UNIT[NAMES.info]}
        </p>
      )}
    </Link>
  );
}

export default PatientCard;
