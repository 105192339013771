import { useDatasetsByQuery, useIcatSessionId } from 'lib/api-hooks';
import { assertDefined } from 'lib/guards';
import { useEffect, useRef } from 'react';
import { useParams } from 'wouter';

import DatasetCard from '../Search/DatasetCard';
import styles from './DatasetList.module.css';

function DatasetList() {
  const { firstLayer, secondLayer } = useParams();
  assertDefined(firstLayer);
  assertDefined(secondLayer);

  const sessionId = useIcatSessionId();

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, [secondLayer]);

  const [organ, description] = secondLayer.split('-');

  const queryParamCore = `SamplePatient_number~eq~${firstLayer},SamplePatient_organ_name~eq~${organ}`;
  const queryParam = description
    ? `${queryParamCore},SamplePatient_organ_description~like~${description}`
    : queryParamCore;

  const datasets = useDatasetsByQuery(queryParam);

  return (
    <div ref={ref} className={styles.datasetContainer}>
      {datasets.map((d) => (
        <DatasetCard key={d.id} dataset={d} sessionId={sessionId} />
      ))}
    </div>
  );
}

export default DatasetList;
