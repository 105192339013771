import { useDatasetsById, useDOI, useFileList } from 'lib/api-hooks';
import { Files, Gallery } from 'lib/dataset';
import styles from 'lib/DatasetPage.module.css';
import { assertDefined } from 'lib/guards';
import { getMetadataByName, trackDownload } from 'lib/helpers';
import { usePageTracking, useScrollToTop } from 'lib/hooks';
import { Helmet } from 'react-helmet';

import { useCitationStore, useModalStore } from '../stores';
import Metadata from './Metadata';
import ModalDownload from './ModalDownload';
import NeuroglancerLink from './NeuroglancerLink';

interface Props {
  datasetId: string;
  isDesktop: boolean;
}

function DatasetPage(props: Props) {
  useScrollToTop();
  usePageTracking();

  const { isDesktop, datasetId } = props;
  assertDefined(datasetId);

  const [dataset] = useDatasetsById([datasetId]);
  const filesList = useFileList(datasetId);
  assertDefined(dataset);
  assertDefined(filesList);

  const doi = useDOI(dataset.id);

  const neuroglancerFile = dataset.gallery.find((file) =>
    file.name.includes('neuroglancer'),
  );

  const { modalSeen } = useCitationStore();
  const { setModalState } = useModalStore();

  function handleClick(evt: React.MouseEvent<HTMLAnchorElement>) {
    if (modalSeen) {
      trackDownload(evt);
      return;
    }

    evt.preventDefault();
    setModalState({
      open: true,
      url: evt.currentTarget.href,
      filename: evt.currentTarget.download,
    });
  }

  return (
    <>
      <Helmet>
        <title>{getMetadataByName(dataset.parameters, 'DOI_title')}</title>
      </Helmet>
      <div>
        <h1 className={styles.heading}>
          {getMetadataByName(dataset.parameters, 'DOI_title')}
        </h1>
        <div className={styles.content}>
          <div className={styles.metadata}>
            <Metadata
              isDesktop={isDesktop}
              doi={doi}
              dataset={dataset}
              fileList={filesList.files}
              neuroglancerFile={neuroglancerFile}
            />
          </div>
          {isDesktop && (
            <div className={styles.scienceFiles}>
              <div>
                <Files
                  doi={doi}
                  files={filesList.files}
                  handleClick={handleClick}
                >
                  {neuroglancerFile && (
                    <NeuroglancerLink resourceId={neuroglancerFile.id} />
                  )}
                  <ModalDownload />
                </Files>
              </div>
              <div>
                <Gallery files={dataset.gallery} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DatasetPage;
