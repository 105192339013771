import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type ModalSeen = boolean;

interface ModalSeenStore {
  modalSeen: ModalSeen;
  setModalSeen: (modalSeen: ModalSeen) => void;
}

interface ModalState {
  open: boolean;
  url?: string;
  ok?: boolean;
  filename?: string;
}

interface ModalStateStore {
  modalState: ModalState;
  setModalState: (modalState: ModalState) => void;
}

export const useCitationStore = create<ModalSeenStore>()(
  persist(
    (set) => ({
      modalSeen: false,
      setModalSeen: (modalSeen) => set({ modalSeen }),
    }),
    { name: 'citationStore' },
  ),
);

export const useModalStore = create<ModalStateStore>()((set) => ({
  modalState: { open: false },
  setModalState: (modalState) => set({ modalState }),
}));
