import { useDatasetsByQuery } from 'lib/api-hooks';
import { assertDefined } from 'lib/guards';
import { getMetadataByName } from 'lib/helpers';
import { useEffect, useRef } from 'react';
import { useParams } from 'wouter';

import OrganCard from './OrganCard';
import styles from './OrganList.module.css';

function OrganList() {
  const { firstLayer } = useParams();
  assertDefined(firstLayer);

  const queryParam = `SamplePatient_number~eq~${firstLayer}`;

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, [firstLayer]);

  const organList = useDatasetsByQuery(queryParam);

  const organUnique = organList.filter((patient, index, self) => {
    return (
      self.findIndex(
        (p) =>
          getMetadataByName(p.parameters, 'Sample_name')?.replaceAll(
            '-',
            '_',
          ) ===
          getMetadataByName(patient.parameters, 'Sample_name')?.replaceAll(
            '-',
            '_',
          ),
      ) === index
    );
  });
  return (
    <div ref={ref} className={styles.sampleContainer}>
      {organUnique.map((d) => (
        <OrganCard key={d.sampleName} dataset={d} patientName={firstLayer} />
      ))}
    </div>
  );
}

export default OrganList;
