import { useNeuroglancerUrl } from 'lib/api-hooks';
import { trackLink } from 'lib/helpers';
import { FiExternalLink } from 'react-icons/fi';

import styles from './NeuroglancerLink.module.css';

interface Props {
  resourceId: string;
}

function NeuroglancerLink(props: Props) {
  const { resourceId } = props;
  const neuroglancerLink = useNeuroglancerUrl(resourceId);

  return (
    <a
      className={styles.pill}
      href={`${neuroglancerLink}`}
      target="_blank"
      rel="noreferrer"
      data-alt2
      onClick={(evt) => trackLink(evt)}
    >
      <span />
      <span>Visualize in Neuroglancer</span>
      <span>
        <FiExternalLink />
      </span>
    </a>
  );
}
export default NeuroglancerLink;
